import axios from "axios";
const https = require('https');


const agent = new https.Agent();

const adapter = axios.create({ 
    baseURL: 'https://chatgroup-back.com/discord',
    responseType: 'json',
    withCredentials: false,
    httpsAgent: agent
});


export default adapter;