
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardMenu from './dashboardMenu/DashboardMenu';
import Sidebar from './sidebar/Sidebar';
import Header from './header/Header';
import ChatBody from './chatBody/ChatBody';
import CreateDialogPage from '../createDialogPage/CreateDialogPage';
import ViewDialogPage from '../viewDialogPage/ViewDialogPage';
import ChangeBotsPage from '../changeBotsPage/ChangeBotsPage';
import AddBotsPage from '../addBotsPage/AddBotsPage';
import AiDialogGeneratePage from '../aiDialogGeneratePage/AiDialogGeneratePage';

const DashboardPage = () => {



  const dashboadBodyStyle = {
    backgroundColor: '#202225',
    color:'#C7C9CB',
    // height: 'calc(100vh+290px)', 
    height: '100vh', 
    overflow: 'hidden', 
  };
  
  const sidebarStyle = {
    position:'relative',
    height: 'calc(100vh - 90px)', 
    padding:'20px',
    color:'#8E9297',
    backgroundColor:'#2F3136',
    borderRadius: '7px 0px 0px 0px',
    overflowY:'scroll',
  };
  const chatBodyStyle = {
    height: 'calc(100vh - 90px)', 
    overflow: 'hidden',
    
  };
  
  const chatsStyle = {
    backgroundColor:'#202225',
    
  }



  const [activePage, setActivePage] = useState('discord');

  const handleSelectMenuItem = (item) =>{
    
    setActivePage(item);
    setSidebarSelectedElemId(null)
    
  }

  const [sidebarSelectedElemId, setSidebarSelectedElemId] = useState(null);

 
  const selectedSidebarElem = (sidebarSelectedElem) =>{
    if (sidebarSelectedElem.type === 'dialog') {
      setSidebarSelectedElemId(sidebarSelectedElem.id);
    }else if (sidebarSelectedElem.type === 'chat') {
      setSidebarSelectedElemId(sidebarSelectedElem.id);
     
    }
  }
  const [refreshSidebarData, setRefreshSidebarData] = useState(null);

  const setRefreshBots = (e) => {
    setRefreshSidebarData(e);
  }

  const [dialogJson, setDialogJson] = useState(null);

  const setGeneratedDialog = (e) => {
    setDialogJson(e);
  }



  return (
    <Container fluid style={dashboadBodyStyle}>
      <Header activePage={activePage}/>
      <Row>
        <Col md={1} style={chatsStyle}> 
          <DashboardMenu  handleSelectMenuItem={handleSelectMenuItem}/>
        </Col>
        {activePage === 'discord' ? (
          <>
            <Col className="p-0" md={2} style={{borderRadius: '7px'}}>
              <div style={sidebarStyle}>
                <Sidebar  selectedSidebarElem={selectedSidebarElem}  activePage={activePage}/>
              </div>
            </Col>
            <Col className="p-0" md={9}>
              <div style={chatBodyStyle}>
                <ChatBody sidebarSelectedElemId={sidebarSelectedElemId}  />
              </div>
            </Col>
          </>
        ) : activePage === 'viewChat' ? (
          <>
            <Col className="p-0" md={2} style={{borderRadius: '7px'}}>
              <div style={sidebarStyle}>
                <Sidebar selectedSidebarElem={selectedSidebarElem}  activePage={activePage}/>
              </div>
            </Col>
            <Col className="p-0" md={9}>
              <div style={chatBodyStyle}>
                <ViewDialogPage sidebarSelectedElemId={sidebarSelectedElemId}/>
              </div>
            </Col>
          </>
        ): activePage === 'changeBots' ? (
          <>
            <Col className="p-0" md={2} style={{borderRadius: '7px'}}>
              <div style={sidebarStyle}>
                <Sidebar selectedSidebarElem={selectedSidebarElem}  activePage={activePage} refreshSidebarData={refreshSidebarData}/>
              </div>
            </Col>
            <Col className="p-0" md={9}>
              <div style={chatBodyStyle}>
                <ChangeBotsPage sidebarSelectedElemId={sidebarSelectedElemId} setRefreshBots={setRefreshBots}/>
              </div>
            </Col>
          </>
        ): activePage === 'addBots' ? (
          <>
            <Col className="p-0" md={3} style={{borderRadius: '7px'}}>
              <div style={sidebarStyle}>
                <Sidebar selectedSidebarElem={selectedSidebarElem}  activePage={activePage} refreshSidebarData={refreshSidebarData}/>
              </div>
            </Col>
            <Col className="p-0" md={8}>
              <div style={chatBodyStyle}>
                <AddBotsPage sidebarSelectedElemId={sidebarSelectedElemId} setRefreshBots={setRefreshBots}/>
              </div>
            </Col>
          </>
        ): activePage === 'dialogsGenerator' ? (
          <>
            <Col className="p-0" md={4} style={{borderRadius: '7px'}}>
              <div style={sidebarStyle}>
                <Sidebar selectedSidebarElem={selectedSidebarElem}  activePage={activePage} refreshSidebarData={refreshSidebarData} generatedDialog={setGeneratedDialog}/>
              </div>
            </Col>
            <Col className="p-0" md={7}>
              <div style={chatBodyStyle}>
                <AiDialogGeneratePage sidebarSelectedElemId={sidebarSelectedElemId} dialogJson={dialogJson}/>
              </div>
            </Col>
          </>
        ): activePage === 'chatsManagment' ? (
          <>
            <Col className="p-0" md={4} style={{borderRadius: '7px'}}>
              <div style={sidebarStyle}>
                <Sidebar selectedSidebarElem={selectedSidebarElem}  activePage={activePage} refreshSidebarData={refreshSidebarData} generatedDialog={setGeneratedDialog}/>
              </div>
            </Col>
            <Col className="p-0" md={7}>
             
            </Col>
          </>
        ):(
          <Col className='p-0' md={11}>
            <CreateDialogPage />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default DashboardPage;



